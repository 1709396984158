import axios from 'axios'
import humps from 'humps'
import { getAuthToken } from '../utils/auth.utils'

class UploaderProvider {
  // eslint-disable-next-line class-methods-use-this
  async uploadFile (file) {
    const authData = getAuthToken()
    const formData = new FormData()
    formData.append('file', file)

    const { data } = await axios.post(`${process.env.VUE_APP_API}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        [authData.key]: authData.value
      }
    })

    return humps.camelizeKeys(data)
  }
}

export default UploaderProvider
