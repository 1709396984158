<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-row class="form-container rounded-lg secondary--text">
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลเวนเดอร์
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col cols="12">
          <image-uploader
            v-model="images"
            :max-file="1"
            label="โลโก้เวนเดอร์"
            square />
        </v-col>
        <v-col cols="12">
          <div class="mb-2">
            สถานะการใช้งาน
          </div>
          <div class="d-flex flex-row align-center pl-4">
            <span class="mr-4">
              ปิด
            </span>
            <v-switch
              v-model="formData.status"
              true-value="available"
              false-value="unavailable"
              inset
              hide-details
              class="mt-0 pt-0" />
            <span>
              เปิด
            </span>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            เลขที่เวนเดอร์
          </div>
          <v-text-field
            v-model="formData.vendorNo"
            placeholder="000001"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="isEdit" />
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            ชื่อเวนเดอร์
          </div>
          <v-text-field
            v-model="formData.name"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            เลขทะเบียนนิติบุคคล
          </div>
          <v-text-field
            v-model="formData.registrationNo"
            :rules="[
              ...textBoxRules,
              (v) => /^[0-9+/-]*$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
            ]"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            เลขที่ใบอนุญาตประกอบธุรกิจ
          </div>
          <v-text-field
            v-model="formData.businessLicenseNo"
            :rules="[
              ...textBoxRules,
              (v) => /^[0-9+/-]*$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
            ]"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            แนบใบอนุญาตประกอบธุรกิจ
          </div>
          <file-uploader
            v-model="files"
            :max-file="1"
            label="อัปโหลดใบอนุญาต" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            ชื่อ - นามสกุล ผู้ติดต่อ
          </div>
          <v-text-field
            v-model="formData.fullName"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            หมายเลขโทรศัพท์
          </div>
          <v-text-field
            v-model="formData.tel"
            type="number"
            :rules="telBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            อีเมล
          </div>
          <v-text-field
            v-model="formData.email"
            type="email"
            :rules="emailBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            เลขบัตรประจำตัวประชาชน / เลขที่หนังสือเดินทาง
          </div>
          <v-text-field
            v-model="formData.citizenNo"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col cols="12">
          <div class="mb-1">
            ที่อยู่
          </div>
          <v-text-field
            v-model="formData.address"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            ถนน
          </div>
          <v-text-field
            v-model="formData.road"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            ซอย
          </div>
          <v-text-field
            v-model="formData.alley"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            แขวง / ตำบล
          </div>
          <v-text-field
            v-model="formData.subDistrict"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="3">
          <div class="mb-1">
            เขต / อำเภอ
          </div>
          <v-text-field
            v-model="formData.district"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            จังหวัด
          </div>
          <v-text-field
            v-model="formData.province"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            รหัสไปรษณีย์
          </div>
          <v-text-field
            v-model="formData.postcode"
            type="number"
            :rules="postcodeRules"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col cols="12">
          <h3>
            ฟอร์มกรอกข้อมูลช่องทางการเงิน
          </h3>
          <v-divider class="mt-3" />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            ธนาคาร
          </div>
          <v-text-field
            v-model="formData.payment.bankName"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            ชื่อบัญชี
          </div>
          <v-text-field
            v-model="formData.payment.accountName"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
        <v-col
          cols="12"
          md="6">
          <div class="mb-1">
            เลขที่บัญชี
          </div>
          <v-text-field
            v-model="formData.payment.accountNo"
            :rules="[
              (v) => /^[0-9]*$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
            ]"
            color="primary"
            outlined
            dense
            hide-details
            required />
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            :disabled="loading"
            style="background-color: white;"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import VendorsProvider from '@/resources/vendors.provider'
import UploaderProvider from '@/resources/uploader.provider'
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'

const VendorsService = new VendorsProvider()
const UploaderService = new UploaderProvider()

export default {
  components: {
    ImageUploader,
    FileUploader
  },
  data: () => ({
    loading: false,
    validate: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    emailBoxRules: [
      (v) => /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    telBoxRules: [
      (v) => (v ? (/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/g.test(v) || 'กรุณากรอกข้อมูลให้ถูกต้อง') : true)
    ],
    postcodeRules: [
      (v) => `${v}`.length <= 5 || 'กรุณากรอกข้อมูลให้ถูกต้อง'
    ],
    formData: {
      vendorNo: '',
      name: '',
      imageUrl: '',
      registrationNo: '',
      businessLicenseNo: '',
      licenseName: '',
      licenseFile: '',
      fullName: '',
      tel: '',
      email: '',
      citizenNo: '',
      address: '',
      road: '',
      alley: '',
      subDistrict: '',
      district: '',
      province: '',
      postcode: '',
      packageAmount: 0,
      orderAmount: 0,
      payment: {
        bankName: '',
        accountName: '',
        accountNo: ''
      },
      status: 'available'
    },
    images: [],
    files: []
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    },
    user () {
      return this.$store.getters['User/user']
    },
    role () {
      return this.user?.role || 'alltotrips'
    }
  },
  mounted () {
    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'VendorList' })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await VendorsService.getItemById(this.$route.params.id)

        this.formData = { ...data }
        this.images = data.imageUrl ? [{
          file: null,
          preview: data.imageUrl,
          mediaUrl: data.imageUrl
        }] : []
        this.files = data.licenseFile ? [
          {
            name: data.licenseFileName,
            mediaUrl: data.licenseFile
          }
        ] : []
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        let imageUrl = this.images[0]?.mediaUrl || ''

        if (this.images[0]?.file) {
          const { data: image } = await UploaderService.uploadFile(this.images[0].file)

          imageUrl = image?.publicUrl
        }

        const licenseFileName = this.files[0]?.name || ''
        let licenseFile = this.files[0]?.mediaUrl || ''

        if (this.files[0]?.file) {
          const { data: image } = await UploaderService.uploadFile(this.files[0].file)

          licenseFile = image?.publicUrl
        }

        if (!this.isEdit) {
          await VendorsService.create({
            ...this.formData,
            imageUrl,
            licenseFileName,
            licenseFile
          })
        } else if (this.role === 'administrator') {
          await VendorsService.updateItemById({
            ...this.formData,
            imageUrl,
            licenseFileName,
            licenseFile
          })
        } else {
          await VendorsService.createDraft({
            ...this.formData,
            imageUrl,
            licenseFileName,
            licenseFile
          })
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        if (this.role === 'administrator') {
          this.$router.push({ name: 'VendorList' })
        } else {
          this.$router.push({
            name: 'VendorDetail',
            params: {
              id: this.$route.params.id
            }
          })
        }
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>
