<template>
  <div class="w-full">
    <div
      v-if="label"
      class="mb-1 secondary--text">
      {{ label }}
    </div>
    <div
      class="upload-container pa-3"
      :square="square">
      <div
        class="uploader"
        @dragover.prevent
        @drop.prevent="onDropFile($event)"
        @click="chooseFile()" />
      <input
        :id="name"
        type="file"
        :multiple="multiple"
        style="display:none"
        :disabled="disabled"
        @change="uploadFromInput()" />
      <div
        v-if="!items.length"
        class="w-full h-full d-flex flex-column justify-center align-center"
        style="gap: 0.5rem;">
        <v-icon color="secondary">
          mdi-tray-arrow-up
        </v-icon>
        <div class="text-center secondary--text">
          ลากและวาง หรือ
          <span class="primary--text">
            เลือกไฟล์
          </span>
          เพื่ออัปโหลด
        </div>
        <span class="accent--text">
          โปรดเลือกไฟล์ที่มีขนาดไม่เกิน 1 mb.
        </span>
      </div>
      <v-row
        v-else
        class="pa-0 h-full">
        <v-col
          v-for="(item, i) in items"
          :key="i"
          class="d-flex child-flex relative"
          cols="12"
          :md="multiple ? 4 : 12">
          <img
            :src="item.preview"
            :alt="`Image preview - ${i}`"
            class="rounded-lg elevation-3">
          <!-- <v-img
            :src="item.preview"
            max-height="360"
            contain
            class="rounded-lg elevation-3" /> -->
          <v-hover
            v-if="!disabled"
            v-slot="{ hover }">
            <div
              class="remove-overlay"
              @click="removeItem(i)">
              <v-icon
                :color="hover ? 'white' : 'error'"
                x-large>
                mdi-delete-empty
              </v-icon>
            </div>
          </v-hover>
        </v-col>
      </v-row>
    </div>
    <div
      v-if="maxFile && maxFile > 1"
      class="mt-1 accent--text w-full text-right"
      style="font-size: 14px;">
      {{ `${items.length}/${maxFile}` }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: 'imageBrowser'
    },
    label: {
      type: String,
      default: ''
    },
    maxFile: {
      type: Number,
      default: null
    },
    multiple: {
      type: Boolean,
      default: false
    },
    autoUpload: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    items: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    chooseFile () {
      if (this.disabled) {
        return
      }

      if (this.maxFile && this.items.length < this.maxFile) {
        document.getElementById(this.name).value = ''
        document.getElementById(this.name).click()
      }
    },
    uploadFromInput () {
      if (this.disabled) {
        return
      }

      const { files } = document.getElementById(this.name)
      this.uploadFiles(files)
    },
    onDropFile (e) {
      if (this.disabled) {
        return
      }

      const { files } = e.dataTransfer

      if (this.maxFile && (this.items.length === this.maxFile)) {
        return
      }

      if (this.maxFile && (files.length + this.items.length) > this.maxFile) {
        const diff = this.maxFile - this.items.length
        const sliced = [...files].slice(0, diff)

        this.uploadFiles(sliced)
      } else {
        this.uploadFiles(files)
      }
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onloadend = (f) => {
          resolve({
            file,
            preview: f.target.result,
            mediaUrl: null
          })
        }

        reader.onerror = () => {
          reject(reader)
        }

        reader.readAsDataURL(file)
      })
    },
    async uploadFiles (files) {
      try {
        const previewPromises = []
        const len = this.items.length

        let previewIndex = 0
        for (const file of files) {
          if (!this.maxFile || (previewIndex + len) < this.maxFile) {
            previewPromises.push(this.getBase64(file))
          }

          previewIndex++
        }

        const previewResults = await Promise.all(previewPromises)
        this.items.push(...previewResults)

        if (this.autoUpload) {
          const resultPromises = []

          // let resultIndex = 0
          // for (const file of files) {
          //   if (!this.maxFile || (resultIndex + len) < this.maxFile) {
          //     resultPromises.push(UploaderService.uploadFile(file))
          //   }

          //   resultIndex++
          // }

          // for (const file of files) {
          //   resultPromises.push(UploaderService.uploadFile(file))
          // }

          const results = await Promise.all(resultPromises)
          for (const [index, result] of results.entries()) {
            this.items[len + index].mediaUrl = result.data.public_url
          }
        }
      } catch (error) {
        console.error('uploadFiles', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      }
    },
    removeItem (index) {
      this.items.splice(index, 1)
    },
    isMaxFile () {
      return this.maxFile && (this.items.length === this.maxFile)
    },
    checkImageUrl (image) {
      return (image?.mediaUrl || !this.autoUpload) ? 'opacity-full' : 'opacity-half'
    }
  }
}
</script>

<style scoped>
.relative {
  position: relative;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.upload-container {
  position: relative;
  z-index: 1;
  aspect-ratio: 2 / 1;
  width: 100%;
  height: fit-content;
  max-height: 388px;
  border-radius: 8px;
  border: 2px dashed #026EAA;
  overflow-y: auto;
}
.upload-container[square] {
  width: auto;
  height: 100%;
  aspect-ratio: 1 / 1;
}
.opacity-half {
  opacity: 0.5;
}
.opacity-full {
  opacity: 1;
}
.remove-overlay {
  position: absolute;
  z-index: 3;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 8px;
  background-color: rgba(235, 87, 87, 0.1);
  color: #EB5757;
  transition-duration: 300ms;
}
.remove-overlay:hover {
  background-color: #EB5757;
  color: white;
}
.uploader {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}
img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  object-position: center;
  aspect-ratio: 1 / 1;
  padding: 1rem;
}

@media screen and (max-width: 960px) {
  .upload-container {
    aspect-ratio: 1 / 1;
  }
}
</style>
