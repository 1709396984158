import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class VendorsProvider extends HttpRequest {
  getItems (query) {
    this.setHeader(getAuthToken())
    return this.get('/vendors', query)
  }

  getItemById (id) {
    this.setHeader(getAuthToken())
    return this.get(`/vendors/${id}`)
  }

  create (payload) {
    this.setHeader(getAuthToken())
    return this.post('/vendors', payload)
  }

  createDraft (payload) {
    this.setHeader(getAuthToken())
    return this.post('/vendor-drafts', payload)
  }

  updateItemById (payload) {
    this.setHeader(getAuthToken())
    return this.patch(`/vendors/${payload.id}`, payload)
  }

  updateItemStatusById (id) {
    this.setHeader(getAuthToken())
    return this.put(`/vendors/${id}/status`)
  }

  updateItemDraftById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/vendors/${id}/draft`)
  }

  deleteItemById (id) {
    this.setHeader(getAuthToken())
    return this.delete(`/vendors/${id}`)
  }
}

export default VendorsProvider
